import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import { PropertyDetailsComponent } from './pages/property-details/property-details.component';
import { PropertySuggestionsComponent } from './pages/property-suggestions/property-suggestions.component';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { GraphQLModule } from './graphql.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { PropertyDetailsInfoComponent } from './pages/property-details-info/property-details-info.component';
import { PropertySuggestionsInfoComponent } from './pages/property-suggestions-info/property-suggestions-info.component';
import { ContactDetailsComponent } from './pages/contact-details/contact-details.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    PropertyDetailsComponent,
    PropertySuggestionsComponent,
    PropertyDetailsInfoComponent,
    PropertySuggestionsInfoComponent,
    ContactDetailsComponent,
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    CommonModule, 
    MatFormFieldModule,MatInputModule,MatSelectModule,MatButtonToggleModule,MatCheckboxModule,
    HttpClientModule,
    MatButtonModule, MatDividerModule, MatIconModule, GraphQLModule,MatProgressSpinnerModule, MatDialogModule,

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
