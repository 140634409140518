<div class="container">
  <div class="content">

    <div class="icon-and-address">
      <mat-icon (click)="back()" class="back-arrow">arrow_back</mat-icon>
      <div class="topAddress" *ngIf="propertyDetails?.shortAddress || propertyDetails?.postcode">
        <mat-icon class="locationIcon">location_on</mat-icon>
        <span class="b1-b locationText">{{propertyDetails?.shortAddress}}, {{propertyDetails?.postcode}}</span>
      </div>
    </div>

    <div class="inputContainer">
      <h1>Book a survey</h1>
      <iframe title="contact" src="https://bot.leadoo.com/bot/visual.html?code=4ef5d3dd" style="border: none" width="100%" height="800px" ></iframe>
    </div>
  </div>
</div>
